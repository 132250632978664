import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Imprint" />

      <article>
        <header>
          <h3>Impressum</h3>
        </header>
        <p>
          <h4>Anbieter gemäß § 5 TMG</h4>
          Timo Maul, Zeppelinstraße 1, 70193 Stuttgart. hello@fromparts.de,
          USt-ID-Nr. DE287418241
        </p>
        <p>
          <h4>Urheberrecht</h4>
          Die durch den Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          Autors. Der Inhalt dieser Websites darf nicht zu kommerziellen Zwecken
          kopiert, verbreitet, verändert oder Dritten zugänglich gemacht werden.
        </p>
        <p>
          <h4>Haftung für Inhalte und Links</h4>
          Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte wird
          keine Gewähr übernommen. Nach §§ 8 bis 10 TMG ist der Seitenbetreiber
          dieser Website als Diensteanbieter nicht verpflichtet, übermittelte
          oder gespeicherte fremde Informationen zu überwachen oder nach
          Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen. Das Angebot dieser Internetseiten enthält Links zu
          externen Webseiten Dritter, auf deren Inhalte kein Einfluss genommen
          werden kann und für die keine Gewähr übernommen wird. Für die Inhalte
          der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
          der Seiten verantwortlich. Rechtswidrige Inhalte waren zum Zeitpunkt
          der Verlinkung nicht erkennbar und werden bei Bekanntwerden von
          Rechtsverletzungen umgehend entfernt.
        </p>
      </article>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
